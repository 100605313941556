<template>
  <!-- Footer -->
  <footer class="-rn-footer" id="footer">
    <div class="rn-footer-wrapper footer">
      <v-container fluid>
        <v-row>
          <v-col lg="12">
            <div class="footer-top">
              <div class="content">
                <span></span>
                <h4>联系我们</h4>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="footer-wrapper ptb--100">
        <v-container fluid>
          <v-row>
            <!-- Start Single Wedget -->
            <v-col lg="3" md="3" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">公司地址</h3>
                <div class="content">
                  <p>
                    山东省青岛市市北区辽宁路 <br />
                    98号M2创意园1号楼A101
                  </p>
                </div>
              </div>
            </v-col>
            <v-col lg="3" md="3" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">联系方式</h3>
                <div class="content">
                  <div class="address">
                    <p>
                      <a href="mailto:19410190@qq.com">19410190@qq.com</a>
                    </p>
                    <p><a href="tel:17057516789">17057516789</a></p>
                  </div>
                </div>
              </div>
            </v-col>
<!--            <v-col lg="3" md="3" sm="6" cols="12">-->
<!--              <div class="footer-widget">-->
<!--                <h3 class="ft-title">合作伙伴</h3>-->
<!--                <div class="content">-->
<!--                  <ul class="quick-link">-->
<!--                    <li v-for="(link, i) in quickLink" :key="i">-->
<!--                      <router-link :to="link.RouteName">{{-->
<!--                        link.name-->
<!--                      }}</router-link>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--            </v-col>-->
          </v-row>
        </v-container>
      </div>
    </div>

<!--    <FooterTwo />-->
  </footer>
  <!--// Footer -->
</template>

<script>
  // import FooterTwo from "./FooterTwo";
  export default {
    components: {
      // FooterTwo,
    },
    data() {
      return {
        quickLink: [
          {
            name: "Blog",
            RouteName: "/blog",
          },
          {
            name: "Service",
            RouteName: "service",
          },
          {
            name: "Portfolio",
            RouteName: "portfolio",
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped></style>
