<template>
    <div class="rn-award-area d-flex bg_color--3" id="awards">
        <div class="rn-award-left w-50 ">
            <v-fade-transition leave-absolute>
                <div v-if="activetab === 1"
                     class="h-full rn-images-bg-wrapper"
                     :style="{ backgroundImage: 'url(' + src1 + ')' }"></div>
            </v-fade-transition>
            <v-fade-transition leave-absolute>
                <div v-if="activetab === 2"
                     class="h-full rn-images-bg-wrapper"
                     :style="{ backgroundImage: 'url(' + src2 + ')' }"></div>
            </v-fade-transition>
            <v-fade-transition leave-absolute>
                <div v-if="activetab === 3"
                     class="h-full rn-images-bg-wrapper"
                     :style="{ backgroundImage: 'url(' + src3 + ')' }"></div>
            </v-fade-transition>
            <v-fade-transition leave-absolute>
                <div v-if="activetab === 4"
                     class="h-full rn-images-bg-wrapper"
                     :style="{ backgroundImage: 'url(' + src4 + ')' }"></div>
            </v-fade-transition>
            <v-fade-transition leave-absolute>
                <div v-if="activetab === 5"
                     class="h-full rn-images-bg-wrapper"
                     :style="{ backgroundImage: 'url(' + src2 + ')' }"></div>
            </v-fade-transition>
        </div>
        <div class="rn-award-right w-50 d-flex align-items-center">
            <div class="text-left award-content rn-plr section-ptb-xl">
                <div class="content">
                    <h2 class="section-title">铸就卓越的源动力</h2>

                    <ul class="tabs rn-award-list">
                        <li>
                          相关资质
                        </li>
                        <li>
                          相关资质
                        </li>
                        <li>
                          相关资质
                        </li>
                        <li>
                          相关资质
                        </li>
                        <li>
                          相关资质
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- End Awards Area -->
</template>

<script>
  export default {
    data() {
      return {
        el: "#award",
        activetab: 1,
        src1: require("../../assets/img/award/awards-1.jpg"),
        src2: require("../../assets/img/award/awards-2.jpg"),
        src3: require("../../assets/img/award/awards-3.jpg"),
        src4: require("../../assets/img/award/awards-4.jpg"),
      };
    },
  };
</script>

<style lang="scss" scoped>
  .rn-award-area {
    overflow: hidden;
  }
  .award-content ul.rn-award-list li a:focus {
    outline: none;
  }
</style>
